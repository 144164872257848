import React, { useState, useEffect } from "react";
import { Button, Form, Input, InputNumber, Switch, Tabs } from "antd";
import ModalCommon from "../../../components/Modal";
import './style.css';
import { TEXT_DEF } from "../../../constant/text";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import API, {ADMIN_WHEEL} from "../../../network/api";
import {validateInput} from "../../../validation";
import desWheel from '../../../image/Wheel.png';
import CustomInputNumber from "../../../components/common/CustomInputNumber";
import RenderSwitch from '../../../components/common/Switch';

const WheelSetting = () => {
  const [wheelData, setWheelData] = useState([]);
  const [isMaintenance, setIsMaintenance] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const [errors, setErrors] = useState({});
  const [lvlSettings, setLvlSettings] = useState({});
  const [level, setLevel] = useState('easy');
  const [loading, setLoading] = useState(false);
  const tabs = {
    1: 'easy',
    2: 'normal',
    3: 'hard',
  };
  const labels = {
    firstLose: 'Thắng lần đầu (đơn vị 1 = 1000 VND)',
    bonusRate: 'Tỷ lệ bonus',
    revRate: 'Tỷ lệ Rev',
    ratioWin: 'Tỷ lệ thắng',
  };

  useEffect(() => {
    const getDataWheelSetting = async () => {
      const response = await API.get(`${ADMIN_WHEEL}/getConfig`);
			const resultGame = response.data?.data?.result;
      setWheelData(resultGame);
    };

    getDataWheelSetting();
  }, []);

  useEffect(() => {
    getDataByModule(wheelData);
  }, [wheelData, level]);

  const getDataByModule = (data) => {
    if (!data) return;
    const filteredData = data?.filter((item) => item?.module === level);

    setLvlSettings(filteredData);
  };

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const showConfirm = (key) => {
    let params;
    const id = wheelData?.find((x) => x.key === key)?.id;
    switch (key) {
      case 'isMaintenance':
        params = {
          key: 'isMaintenance',
          value: isMaintenance.toString(),
        };
        break;
      case 'revRate':
        params = {
          key: 'revRate',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'firstLose':
        params = {
          key: 'firstLose',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'bonusRate':
        params = {
          key: 'bonusRate',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'ratioWin':
        params = {
          key: 'ratioWin',
          value: valueToUpdate[key].toString(),
        };
        break;
    }
    const dataReq = {
      id: id,
      params: params,
    };
    setDataUp(dataReq);
    setIsShowModalConfirm(true);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(
      `${ADMIN_WHEEL}/updateConfig/${dataUp?.id}`,
      dataUp?.params
    )
      .then((response) => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (id, newValue) => {
    const updatedData = lvlSettings.map((record) => (record.id === id ? { ...record, value: newValue } : record));
    setLvlSettings(updatedData);

    const errMsg = validateInput(newValue, TEXT_DEF.text0208);
    setErrors((prevErrors) => ({ ...prevErrors, [id]: errMsg }));
  };

  const handleUpdate = async (index) => {
    const limitedNumbers = {
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
    };
    const recordToUpdate = lvlSettings.find((record) => record.id === index);
    const numbersArr = recordToUpdate.value.split(',').filter((num) => num.trim() !== '');
    const module = lvlSettings.find((record) => record.value === recordToUpdate.value);
    const limitedCount = limitedNumbers[module.key];

    if (numbersArr.length < limitedCount) {
      alert(TEXT_DEF.text0209);
      return;
    }

		const {id, key, value} = recordToUpdate;

		await API.patch(
      `${ADMIN_WHEEL}/updateConfig/${id}`, {key, value})
      .then((response) => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
	};

	const handleValueChange = (key,newValue) => {
		setValueToUpdate((prev) => ({...prev, [key]: Number(newValue)}));
	}

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const renderInputFields = () => {
		if (!wheelData || wheelData.length === 0) return;
		
		const filteredData = wheelData.filter((item) => item.key === 'firstLose' || item.key === 'bonusRate' || item.key === 'revRate' || item.key === 'ratioWin');

    return (
      <>
        {filteredData.length > 0 &&
          filteredData?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key]}</div>
                <div className="col-6">
                  <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleValueChange(item.key, e)} />
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };
	
  const renderEasy = () => {
    return (
      <>
        <Form layout="horizontal" autoComplete="off">
          {lvlSettings.length > 0 &&
            lvlSettings.map((record, index) => (
              <>
                <div className="form-group row mb-2">
                  <div className="col-1 flex">{record.key} Hàng</div>
                  <div className="col-10">
                    <Form.Item name="abc" key={record.id}>
                      <Input
                        value={record.value}
                        onChange={(e) => handleInputChange(record.id, e.target.value)}
                        style={{
                          marginRight: '10px',
                          width: index === 0 ? '220px' : index === 1 ? '420px' : index === 2 ? '600px' : index === 3 ? '810px' : index === 4 ? '1000px' : '',
                          borderColor: errors[record.id] ? '#ff4d4f' : undefined,
                        }}
                      />
                      <Button type="primary" onClick={() => handleUpdate(record.id)}>
                        {TEXT_DEF.text0016}
                      </Button>
                      {errors[record.id] && <div style={{ color: 'red' }}>{errors[record.id]}</div>}
                    </Form.Item>
                  </div>
                </div>
              </>
            ))}
        </Form>
      </>
    );
  };

  const renderNormal = () => {
    return (
      <>
        <Form layout="horizontal" autoComplete="off">
          {lvlSettings.length > 0 &&
            lvlSettings.map((record, index) => (
              <>
                <div className="form-group row mb-2">
                  <div className="col-1 flex">{record.key} Hàng</div>
                  <div className="col-10">
                    <Form.Item name="abc" key={record.id}>
                      <Input
                        value={record.value}
                        onChange={(e) => handleInputChange(record.id, e.target.value)}
                        style={{
                          marginRight: '10px',
                          width: index === 0 ? '220px' : index === 1 ? '420px' : index === 2 ? '600px' : index === 3 ? '810px' : index === 4 ? '1000px' : '',
                          borderColor: errors[record.id] ? '#ff4d4f' : undefined,
                        }}
                      />
                      <Button type="primary" onClick={() => handleUpdate(record.id)}>
                        {TEXT_DEF.text0016}
                      </Button>
                      {errors[record.id] && <div style={{ color: 'red' }}>{errors[record.id]}</div>}
                    </Form.Item>
                  </div>
                </div>
              </>
            ))}
        </Form>
      </>
    );
  };

  const renderHard = () => {
    return (
      <>
        <Form layout="horizontal" autoComplete="off">
          {lvlSettings.length > 0 &&
            lvlSettings.map((record, index) => (
              <>
                <div className="form-group row mb-2">
                  <div className="col-1 flex">{record.key} Hàng</div>
                  <div className="col-10">
                    <Form.Item name="abc" key={record.id}>
                      <Input
                        value={record.value}
                        onChange={(e) => handleInputChange(record.id, e.target.value)}
                        style={{
                          marginRight: '10px',
                          width: index === 0 ? '220px' : index === 1 ? '420px' : index === 2 ? '600px' : index === 3 ? '810px' : index === 4 ? '1000px' : '',
                          borderColor: errors[record.id] ? '#ff4d4f' : undefined,
                        }}
                      />
                      <Button type="primary" onClick={() => handleUpdate(record.id)}>
                        {TEXT_DEF.text0016}
                      </Button>
                      {errors[record.id] && <div style={{ color: 'red' }}>{errors[record.id]}</div>}
                    </Form.Item>
                  </div>
                </div>
              </>
            ))}
        </Form>
      </>
    );
  };

  const items = [
    {
      key: '1',
      label: TEXT_DEF.text0207,
      children: renderEasy(),
    },
    {
      key: '2',
      label: TEXT_DEF.text0154,
      children: renderNormal(),
    },
    {
      key: '3',
      label: TEXT_DEF.text0155,
      children: renderHard(),
    },
  ];

  const onChange = (value) => {
    const tabIndex = tabs[value];
    setLevel(tabIndex);
  };

  return (
    <>
      {wheelData ? (
        <div className="col-12 flex-col" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <RenderSwitch data={wheelData} labels={labels} handleChangeSwitch={handleChangeSwitch} hasRight={hasRight} handleGetKey={handleGetKey} />
          <div style={{ margin: '10px 0px' }} />
          {renderInputFields(wheelData)}
          <div style={{ margin: '10px 0px' }} />
          {isShowModalConfirm && (
            <ModalCommon
              isShow={isShowModalConfirm}
              message={TEXT_DEF.text0071}
              txtBtnAcc={TEXT_DEF.text0008}
              textBtnClose={TEXT_DEF.text0072}
              actionNext={onRequest}
              close={() => {
                setIsShowModalConfirm(false);
                setDataUp(undefined);
              }}
            />
          )}
          {isShowModalSuccess && (
            <ModalCommon
              isShow={isShowModalSuccess}
              message={TEXT_DEF.text0073}
              txtBtnAcc={TEXT_DEF.text0007}
              actionNext={() => {
                setIsShowModalSuccess(false);
              }}
              close={() => {
                setIsShowModalSuccess(false);
              }}
            />
          )}
        </div>
      ) : null}

      <div className="col-12">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        <div className="img-description flex justify-center items-center flex-col">
          <div className="text-description">
            <p>{TEXT_DEF.text0210}</p>
          </div>
          <div className="img">
            <img src={desWheel} alt="wheel" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WheelSetting;
