const RIGHTS = {
    Super: {
        Code: 0,
        Name: 'super',
        Module: 'super',
        Module_Title: 'super',
        Right_Title: 'super',
        Description: 'super_right',
    },
    // Basic: {
    //     Code: 1,
    //     Name: 'basic',
    //     Module: 'basic',
    //     Module_Title: 'basic',
    //     Right_Title: 'basic',
    //     Description: 'basic_right',
    // },
    // management users admin
    CreateAdminUser: {
        Code: 2,
        Name: 'create_admin_user',
        Module: 'admin_user',
        Module_Title: 'Quản lý danh sách quản trị',
        Right_Title: 'Tạo người dùng',
        Description: 'create_admin_user',
        App: 'lottery',
    },
    EditAdminUser: {
        Code: 3,
        Name: 'edit_admin_user',
        Module: 'admin_user',
        Module_Title: 'Quản lý danh sách quản trị',
        Right_Title: 'Chỉnh sửa người dùng',
        Description: 'edit_admin_user',
        App: 'lottery',
    },
    DeleteAdminUser: {
        Code: 4,
        Name: 'delete_admin_user',
        Module: 'admin_user',
        Module_Title: 'Quản lý danh sách quản trị',
        Right_Title: 'Xoá người dùng',
        Description: 'delete_admin_user',
        App: 'lottery',
    },
    ShowListAdminUsers: {
        Code: 5,
        Name: 'show_list_admin_user',
        Module: 'admin_user',
        Module_Title: 'Quản lý danh sách quản trị',
        Right_Title: 'Hiển thị danh sách người dùng',
        Description: 'show_list_admin_user',
        App: 'lottery',
    },
    // Allow selections bookmarker to search or create
    // AllowSearchFromBookmarker: {
    //     Code: 7,
    //     Name: 'allow_search_from_bookmarker',
    //     Module: 'selection_bookmarker',
    //     Module_Title: 'Cho phép chọn bookmarker',
    //     Right_Title: 'Cho phép tìm kiếm theo bookmarker',
    //     Description: 'allow_search_from_bookmarker',
    //     App: 'lottery',
    // },
    // AllowCreateOrEditFromBookmarker: {
    //     Code: 8,
    //     Name: 'allow_create_or_edit_from_bookmarker',
    //     Module: 'selection_bookmarker',
    //     Module_Title: 'Cho phép chọn bookmarker',
    //     Right_Title: 'Cho phép tạo theo bookmarker',
    //     Description: 'allow_create_or_edit_from_bookmarker',
    //     App: 'lottery',
    // },
    // report orders by book marker
    ShowReportOrdersByBookmarker: {
        Code: 9,
        Name: 'show_report_orders_by_bookmarker',
        Module: 'report_orders_by_bookmarker',
        Module_Title: 'Quản lý thống kê theo nhà điều hành',
        Right_Title: 'Hiển thị orders theo bookmarker',
        Description: 'show_report_orders_by_bookmarker',
        App: 'lottery',
    },
    ExportExcelReportOrdersByBookmarker: {
        Code: 11,
        Name: 'export_excel_report_orders_by_bookmarker',
        Module: 'report_orders_by_bookmarker',
        Module_Title: 'Quản lý thống kê theo nhà điều hành',
        Right_Title: 'Trích xuất file excel',
        Description: 'export_excel_report_orders_by_bookmarker',
        App: 'lottery',
    },
    // report orders by game
    ShowReportOrdersByGame: {
        Code: 12,
        Name: 'show_report_orders_by_game',
        Module: 'report_orders_by_game',
        Module_Title: 'Quản lý thống kê theo game',
        Right_Title: 'Hiển thị orders theo game',
        Description: 'show_report_orders_by_game',
        App: 'lottery',
    },
    ExportExcelReportOrdersByGame: {
        Code: 14,
        Name: 'export_excel_report_orders_by_game',
        Module: 'report_orders_by_game',
        Module_Title: 'Quản lý thống kê theo game',
        Right_Title: 'Trích xuất file excel',
        Description: 'export_excel_report_orders_by_game',
        App: 'lottery',
    },
    // report orders by user
    ShowReportOrdersByUser: {
        Code: 15,
        Name: 'show_report_orders_by_user',
        Module: 'report_orders_by_user',
        Module_Title: 'Quản lý thống kê theo người chơi',
        Right_Title: 'Hiển thị orders theo người chơi',
        Description: 'show_report_orders_by_user',
        App: 'lottery',
    },
    ExportExcelReportOrdersByUser: {
        Code: 17,
        Name: 'export_excel_report_orders_by_user',
        Module: 'report_orders_by_user',
        Module_Title: 'Quản lý thống kê theo người chơi',
        Right_Title: 'Trích xuất file excel',
        Description: 'export_excel_report_orders_by_user',
        App: 'lottery',
    },
    // report orders
    ShowReportOrders: {
        Code: 18,
        Name: 'show_report_by_orders',
        Module: 'report_by_orders',
        Module_Title: 'Quản lý thống kê theo đơn cược',
        Right_Title: 'Hiển thị danh sách orders',
        Description: 'show_report_by_orders',
        App: 'lottery',
    },
    ExportExcelReportOrders: {
        Code: 20,
        Name: 'export_excel_report_orders',
        Module: 'report_by_orders',
        Module_Title: 'Quản lý thống kê theo đơn cược',
        Right_Title: 'Trích xuất file excel',
        Description: 'export_excel_report_orders',
        App: 'lottery',
    },
    // lottery award 
    ShowLotteryAwardXNS: {
        Code: 21,
        Name: 'show_lottery_award_xns',
        Module: 'lotterty_award_xns',
        Module_Title: 'Quản lý tra cứu kết quả XSN',
        Right_Title: 'Hiển thị giải thưởng xổ số',
        Description: 'show_report_by_orders',
        App: 'lottery',
    },
    // wallet history
    ShowWalletHistory: {
        Code: 23,
        Name: 'show_wallet_history',
        Module: 'wallet_history',
        Module_Title: 'Quản lý lịch sử dòng tiền',
        Right_Title: 'Hiển thị lịch sử ví',
        Description: 'show_wallet_history',
        App: 'common',
    },
    ExportExcelWalletHistory: {
        Code: 25,
        Name: 'export_excel_wallet_history',
        Module: 'wallet_history',
        Module_Title: 'Quản lý lịch sử dòng tiền',
        Right_Title: 'Trích xuất excel',
        Description: 'export_excel_wallet_history',
        App: 'common',
    },
    // setting system - homepage
    // setting layout
    ShowSettingLayout: {
        Code: 26,
        Name: 'show_setting_layout',
        Module: 'setting_layout',
        Module_Title: 'Quản lý cài đặt giao diện',
        Right_Title: 'Hiện thị cài đặt giao diện',
        Description: 'show_setting_layout',
        App: 'common',
    },
    EditSettingLayout: {
        Code: 27,
        Name: 'edit_setting_layout',
        Module: 'setting_layout',
        Module_Title: 'Quản lý cài đặt giao diện',
        Right_Title: 'Chỉnh sửa cài đặt giao diện',
        Description: 'edit_setting_layout',
        App: 'common',
    },
    // setting bxh fake
    ShowSettingRankFake: {
        Code: 28,
        Name: 'show_setting_rank_fake',
        Module: 'setting_rank_fake',
        Module_Title: 'Quản lý cài đặt BXH Fake',
        Right_Title: 'Hiển thị bảng xếp hạng giả',
        Description: 'show_setting_rank_fake',
        App: 'common',
    },
    CreateSettingRankFake: {
        Code: 29,
        Name: 'create_setting_rank_fake',
        Module: 'setting_rank_fake',
        Module_Title: 'Quản lý cài đặt BXH Fake',
        Right_Title: 'Tạo bảng xếp hạng giả',
        Description: 'create_setting_rank_fake',
        App: 'common',
    },
    // setting Q&A
    ShowSettingQA: {
        Code: 30,
        Name: 'show_setting_qa',
        Module: 'setting_qa',
        Module_Title: 'Quản lý Q&A',
        Right_Title: 'Hiển thị cài đặt qa',
        Description: 'show_setting_qa',
        App: 'common',
    },
    CreateSettingQA: {
        Code: 31,
        Name: 'create_setting_qa',
        Module: 'setting_qa',
        Module_Title: 'Quản lý Q&A',
        Right_Title: 'Tạo qa',
        Description: 'create_setting_qa',
        App: 'common',
    },
    EditSettingQA: {
        Code: 32,
        Name: 'edit_setting_qa',
        Module: 'setting_qa',
        Module_Title: 'Quản lý Q&A',
        Right_Title: 'Chỉnh sửa qa',
        Description: 'edit_setting_qa',
        App: 'common',
    },
    DeleteSettingQA: {
        Code: 33,
        Name: 'delete_setting_qa',
        Module: 'setting_qa',
        Module_Title: 'Quản lý Q&A',
        Right_Title: 'Xoá qa',
        Description: 'delete_setting_qa',
        App: 'common',
    },
    // setting game text
    ShowSettingGameText: {
        Code: 34,
        Name: 'show_setting_game_text',
        Module: 'setting_game_text',
        Module_Title: 'Quản lý cài đặt text hiển thị',
        Right_Title: 'Hiển thị danh sách văn bản',
        Description: 'show_setting_game_text',
        App: 'common',
    },
    EditSettingGameText: {
        Code: 35,
        Name: 'edit_setting_game_text',
        Module: 'setting_game_text',
        Module_Title: 'Quản lý cài đặt text hiển thị',
        Right_Title: 'Chỉnh sửa văn bản',
        Description: 'edit_setting_game_text',
        App: 'common',
    },
    // setting bonus

    // setting game
    // setting game xsn
    ShowSettingXSNCommon: {
        Code: 36,
        Name: 'show_setting_xsn_common',
        Module: 'setting_xsn_common',
        Module_Title: 'Quản lý cài đặt thông số',
        Right_Title: 'Hiển thị cài đặt chung xổ số',
        Description: 'show_setting_xsn_common',
        App: 'lottery',
    },
    EditSettingXSNCommon: {
        Code: 37,
        Name: 'edit_setting_xsn_common',
        Module: 'setting_xsn_common',
        Module_Title: 'Quản lý cài đặt thông số',
        Right_Title: 'Chỉnh sửa cài đặt chung xổ số',
        Description: 'edit_setting_xsn_common',
        App: 'lottery',
    },
    ShowSettingXSNBonus: {
        Code: 38,
        Name: 'show_setting_xsn_bonus',
        Module: 'setting_xsn_bonus',
        Module_Title: 'Quản lý cài đặt tiền thưởng',
        Right_Title: 'Hiển thị cài đặt bonus',
        Description: 'show_setting_xsn_bonus',
        App: 'lottery',
    },
    EditSettingXSNBonus: {
        Code: 39,
        Name: 'edit_setting_xsn_bonus',
        Module: 'setting_xsn_bonus',
        Module_Title: 'Quản lý cài đặt tiền thưởng',
        Right_Title: 'Chỉnh sửa bonus xổ số',
        Description: 'edit_setting_xsn_bonus',
        App: 'lottery',
    },
    CreateSettingXSNBonus: {
        Code: 56,
        Name: 'create_setting_xsn_bonus',
        Module: 'setting_xsn_bonus',
        Module_Title: 'Quản lý cài đặt tiền thưởng',
        Right_Title: 'Tạo bonus xổ số',
        Description: 'create_setting_xsn_bonus',
        App: 'lottery',
    },
    DeleteSettingXSNBonus: {
        Code: 57,
        Name: 'delete_setting_xsn_bonus',
        Module: 'setting_xsn_bonus',
        Module_Title: 'Quản lý cài đặt tiền thưởng',
        Right_Title: 'Xoá bonus xổ số',
        Description: 'delete_setting_xsn_bonus',
        App: 'lottery',
    },
    // setting game lottery traditional

    // setting game originals
    ShowSettingOriginals: {
        Code: 40,
        Name: 'show_setting_orginals',
        Module: 'setting_orginals',
        Module_Title: 'Quản lý cài đặt Originals',
        Right_Title: 'Hiện thị cài đặt orginals',
        Description: 'show_setting_orginals',
        App: 'casino',
    },
    EditSettingOriginals: {
        Code: 41,
        Name: 'edit_setting_orginals',
        Module: 'setting_orginals',
        Module_Title: 'Quản lý cài đặt Originals',
        Right_Title: 'Chỉnh sửa cài đặt orginals',
        Description: 'edit_setting_orginals',
        App: 'casino',
    },
    // game manager
    ShowGameManager: {
        Code: 46,
        Name: 'show_game_manager',
        Module: 'game_manager',
        Module_Title: 'Quản lý trò chơi',
        Right_Title: 'Hiển thị cài đặt quản lý game',
        Description: 'show_game_manager',
        App: 'common',
    },
    EditGameManager: {
        Code: 47,
        Name: 'edit_game_manager',
        Module: 'game_manager',
        Module_Title: 'Quản lý trò chơi',
        Right_Title: 'Chỉnh sửa cài đặt quản lý game',
        Description: 'edit_game_manager',
        App: 'common',
    },
    // roles
    CreateRole: {
        Code: 48,
        Name: 'create_role',
        Module: 'roles',
        Module_Title: 'Quản lý vai trò quản trị',
        Right_Title: 'Tạo quyền',
        Description: 'create_role',
        App: 'lottery',
    },
    EditRole: {
        Code: 49,
        Name: 'edit_role',
        Module: 'roles',
        Module_Title: 'Quản lý vai trò quản trị',
        Right_Title: 'Chỉnh sửa quyền',
        Description: 'edit_role',
        App: 'lottery',
    },
    DeleteRole: {
        Code: 50,
        Name: 'delete_role',
        Module: 'roles',
        Module_Title: 'Quản lý vai trò quản trị',
        Right_Title: 'Xoá quyền',
        Description: 'delete_role',
        App: 'lottery',
    },
    ShowListRoles: {
        Code: 51,
        Name: 'show_list_roles',
        Module: 'roles',
        Module_Title: 'Quản lý vai trò quản trị',
        Right_Title: 'Hiển thị danh sách quyền',
        Description: 'show_list_roles',
        App: 'lottery',
    },
    // management bookmarkers
    CreateBookmarker: {
        Code: 52,
        Name: 'create_bookmarker',
        Module: 'management_bookmarkers',
        Module_Title: 'Quản lý nhà điều hành',
        Right_Title: 'Tạo nhà cái',
        Description: 'create_bookmarker',
        App: 'lottery',
    },
    EditBookmarker: {
        Code: 53,
        Name: 'edit_bookmarker',
        Module: 'management_bookmarkers',
        Module_Title: 'Quản lý nhà điều hành',
        Right_Title: 'Chỉnh sửa nhà cái',
        Description: 'edit_bookmarker',
        App: 'lottery',
    },
    DeleteBookmarker: {
        Code: 54,
        Name: 'delete_bookmarker',
        Module: 'management_bookmarkers',
        Module_Title: 'Quản lý nhà điều hành',
        Right_Title: 'Xoá nhà cái',
        Description: 'delete_bookmarker',
        App: 'lottery',
    },
    ShowListBookmarkers: {
        Code: 55,
        Name: 'show_bookmarkers',
        Module: 'management_bookmarkers',
        Module_Title: 'Quản lý nhà điều hành',
        Right_Title: 'Hiển thị danh sách nhà cái',
        Description: 'management_bookmarkers',
        App: 'lottery',
    },
    // management users play game
    ShowListUsersPlayGame: {
        Code: 56,
        Name: 'show_list_users_play_game',
        Module: 'management_users_play_game',
        Module_Title: 'Quản lý người chơi',
        Right_Title: 'Hiển thị danh sách người chơi',
        Description: 'management_users_play_game',
        App: 'lottery',
    },
    ExportExcelListUsersPlayGame: {
        Code: 57,
        Name: 'export_excel_list_users_play_game',
        Module: 'management_users_play_game',
        Module_Title: 'Quản lý người chơi',
        Right_Title: 'Trích xuất file excel',
        Description: 'management_users_play_game',
        App: 'lottery',
    },
    BlockUserPlayGame: {
        Code: 58,
        Name: 'block_user_play_game',
        Module: 'management_users_play_game',
        Module_Title: 'Quản lý người chơi',
        Right_Title: 'Khoá người chơi',
        Description: 'management_users_play_game',
        App: 'lottery',
    },
    ChangeNickNameUserPlayGame: {
        Code: 59,
        Name: 'change_nick_name_user_play_game',
        Module: 'management_users_play_game',
        Module_Title: 'Quản lý người chơi',
        Right_Title: 'Thay đổi nickname người chơi',
        Description: 'management_users_play_game',
        App: 'lottery',
    },
    ChangeLevelUserPlayGame: {
        Code: 60,
        Name: 'change_level_user_play_game',
        Module: 'management_users_play_game',
        Module_Title: 'Quản lý người chơi',
        Right_Title: 'Thay đổi level người chơi',
        Description: 'management_users_play_game',
        App: 'lottery',
    },
		// ShowWhiteList: {
    //     Code: 61,
    //     Name: 'show_white_list',
    //     Module: 'management_white_list',
    //     Module_Title: 'Quản lý BO white list',
    //     Right_Title: 'Hiển thị White list',
    //     Description: 'management_white_list',
    //     App: 'lottery',
    // },
    EditWhiteList: {
        Code: 62,
        Name: 'edit_white_list',
        Module: 'management_white_list',
        Module_Title: 'Quản lý BO white list',
        Right_Title: 'Chỉnh sửa White list',
        Description: 'management_white_list',
        App: 'lottery',
    },
    DeleteWhiteList: {
        Code: 63,
        Name: 'delete_white_list',
        Module: 'management_white_list',
        Module_Title: 'Quản lý BO white list',
        Right_Title: 'Xoá White list',
        Description: 'management_white_list',
        App: 'lottery',
    },
    ShowWhiteListByBookmaker: {
        Code: 64,
        Name: 'show_white_list_by_bookmaker',
        Module: 'management_white_list',
        Module_Title: 'Quản lý BO white list',
        Right_Title: 'Hiển thị White list theo nhà điều hành',
        Description: 'management_white_list',
        App: 'lottery',
    },
    CreateWhiteList: {
        Code: 65,
        Name: 'create_white_list',
        Module: 'management_white_list',
        Module_Title: 'Quản lý BO white list',
        Right_Title: 'Tạo mới White list',
        Description: 'management_white_list',
        App: 'lottery',
	  },
		// ShowAllWhiteList: {
    //     Code: 66,
    //     Name: 'show_all_white_list',
    //     Module: 'management_white_list',
    //     Module_Title: 'Quản lý BO white list',
    //     Right_Title: '',
    //     Description: 'management_white_list',
    //     App: 'lottery',
    // },
    RewardManagement: {
        Code: 68,
        Name: 'show_list_reward',
        Module: 'management_reward_list',
        Module_Title: 'QUẢN LÝ TRẢ THƯỞNG',
        Right_Title: 'QUẢN LÝ TRẢ THƯỞNG',
        Description: 'management_reward_list',
        App: 'lottery',
    },
};

const RIGHTS_DEFAULT = ['basic'];

export {
    RIGHTS,
    RIGHTS_DEFAULT,
};