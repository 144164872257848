import React, { useState, useEffect } from 'react';
import { TEXT_DEF } from "../../../constant/text";
import { Button, Switch } from "antd";
import ModalCommon from "../../../components/Modal";
import API, { ADMIN_HILO } from "../../../network/api";
import { RIGHTS } from "../../../constant/rights";
import {hasRight} from "../../../components/until/right";
import CustomInputNumber from "../../../components/common/CustomInputNumber";
import RenderSwitch from '../../../components/common/Switch';

const HiloSetting = () => {
  const [isMaintenance, setIsMaintenance] = useState()
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  const labels = {
    firstLose: 'Thắng lần đầu (đơn vị 1 = 1000 VND)',
    ratioWin: 'Tỉ lệ thắng',
    ratioRev: 'Tỷ lệ Rev',
    ratioBonus: 'Tỷ lệ bonus',
    multiDefault: 'Tỉ lệ mặc định lượt đầu',
    maxSkip: 'Giới hạn bỏ qua',
    rateMulti_K: 'Hệ số HiLo_K',
    rateMulti_Q: 'Hệ số HiLo_Q',
    rateMulti_J: 'Hệ số HiLo_J',
    rateMulti_10: 'Hệ số HiLo_10',
    rateMulti_9: 'Hệ số HiLo_9',
    rateMulti_8: 'Hệ số HiLo_8',
    rateMulti_7: 'Hệ số HiLo_7',
    rateMulti_6: 'Hệ số HiLo_6',
    rateMulti_5: 'Hệ số HiLo_5',
    rateMulti_4: 'Hệ số HiLo_4',
    rateMulti_3: 'Hệ số HiLo_3',
    rateMulti_2: 'Hệ số HiLo_2',
    rateMulti_A: 'Hệ số HiLo_A',
	};
	const ltsRatio = ['firstLose', 'ratioWin', 'ratioRev', 'ratioBonus', 'multiDefault', 'maxSkip'];

  useEffect(() => {
    const getDataHilo = async () => {
      const response = await API.get(`${ADMIN_HILO}/getConfig`);
      const resultGame = response.data?.data?.result;
      setData(resultGame);
    };

    getDataHilo();
  }, []);

  const showConfirm = (key) => {
    let params;
    const id = data?.find(x => x.key == key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString()
        }
        break;
      case "firstLose":
        params = {
          key: "firstLose",
          value: valueToUpdate[key].toString()
        }
        break;
      case "ratioRev":
        params = {
          key: 'ratioRev',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratioBonus":
        params = {
          key: 'ratioBonus',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "multiDefault":
        params = {
          key: 'multiDefault',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "maxSkip":
        params = {
          key: 'maxSkip',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratioWin":
        params = {
          key: 'ratioWin',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "rateMulti_K":
        params = {
          key: 'rateMulti_K',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "rateMulti_Q":
        params = {
          key: 'rateMulti_Q',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "rateMulti_J":
        params = {
          key: 'rateMulti_J',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "rateMulti_10":
        params = {
          key: 'rateMulti_10',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "rateMulti_9":
        params = {
          key: 'rateMulti_9',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "rateMulti_8":
        params = {
          key: "rateMulti_8",
          value: valueToUpdate[key].toString(),
        }
        break;
      case "rateMulti_7":
        params = {
          key: "rateMulti_7",
          value: valueToUpdate[key].toString(),
        }
        break;
      case "rateMulti_6":
        params = {
          key: "rateMulti_6",
          value: valueToUpdate[key].toString(),
        }
        break;
      case "rateMulti_5":
        params = {
          key: "rateMulti_5",
          value: valueToUpdate[key].toString(),
        }
        break;
      case "rateMulti_4":
        params = {
          key: "rateMulti_4",
          value: valueToUpdate[key].toString(),
        }
        break;
      case "rateMulti_3":
        params = {
          key: "rateMulti_3",
          value: valueToUpdate[key].toString(),
        }
        break;
      case "rateMulti_2":
        params = {
          key: "rateMulti_2",
          value: valueToUpdate[key].toString(),
        }
        break;
      case "rateMulti_A":
        params = {
          key: "rateMulti_A",
          value: valueToUpdate[key].toString(),
        }
        break;
      default: break;
    }
    const dataReq = {
      id: id,
      params: params
    }
    setDataUp(dataReq);
    setIsShowModalConfirm(true)
  }

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(`${ADMIN_HILO}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then(response => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  const filteredData = data.filter((item) => item.key !== 'isMaintenance');

  const sortedRatio = filteredData.sort((a, b) => {
    const withRatio = ltsRatio.includes(a.key);
    const withoutRatio = ltsRatio.includes(b.key);

    if (withRatio && !withoutRatio) return -1;
    if (!withRatio && withoutRatio) return 1;

    return 0;
  });

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const handleInputChange = (key, newValue) => {
    setValueToUpdate((prev) => ({ ...prev, [key]: Number(newValue) }));
  };

  const handleInputHiloChange = (key, newValue, isValue1) => {
    const foundItem = filteredData.find((item) => item.key === key);
    if (foundItem) {
      const valueParts = foundItem.value.split('_');

      if (isValue1) {
        valueParts[0] = newValue;
      } else {
        valueParts[1] = newValue;
      }

      const updatedValue = valueParts.join('_');

      setValueToUpdate((prev) => ({
        ...prev,
        [key]: updatedValue,
      }));
    }
  };

  const renderInputFields = () => {
    if (!data || data.length === 0) return;

    const checkedKey = ['firstLose', 'ratioWin', 'ratioRev', 'ratioBonus', 'multiDefault', 'maxSkip'];

    return (
      <>
        {sortedRatio.length > 0 &&
          sortedRatio?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key]}</div>
                <div className="col-06">
                  {checkedKey.every((el) => !item?.key.includes(el)) ? (
                    renderInputFieldsForHilo(item)
                  ) : (
                    <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleInputChange(item.key, e)} />
                  )}
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left" style={{ marginLeft: checkedKey.every((el) => !item?.key?.includes(el)) ? '5rem' : null }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };
	
  const renderInputFieldsForHilo = (item) => {
    const valueParts = item.value.split('_');
    const dataHilo = {
      value1: valueParts[0],
      value2: valueParts[1],
    };

    return (
      <div className="col-12 flex">
        <div className="col-6">
          <div className="col-12 flex" style={{ alignItems: 'center' }}>
            <div className="col-3 text-left">Cao: </div>
            <CustomInputNumber min={0} max={9999} step={0.01} value={dataHilo.value1} onChange={(e) => handleInputHiloChange(item.key, e, true)} isHilo={true} />
            <div className="col-3 text-left ml-6">Thấp: </div>
            <CustomInputNumber min={0} max={9999} step={0.01} value={dataHilo.value2} onChange={(e) => handleInputHiloChange(item.key, e, false)} isHilo={true} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
    { data ? 
      <div className="col-12 flex-col"
        style={{ display: "flex", marginTop: 30, alignItems: "center" }}
      >
				<RenderSwitch data={data} labels={labels} handleChangeSwitch={handleChangeSwitch} hasRight={hasRight} handleGetKey={handleGetKey} />
        <div style={{margin: "10px 0px"}} />
        {renderInputFields()}
      </div> : null }
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => { setIsShowModalConfirm(false); setDataUp(undefined) }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  )
}

export default HiloSetting;