import { Button, Switch } from 'antd';
import { RIGHTS } from '../../../constant/rights';
import { TEXT_DEF } from '../../../constant/text';

const RenderSwitch = ({ data, labels, handleChangeSwitch, hasRight, handleGetKey }) => {
  if (!data || data.length === 0) return null;

  const keysToFilter = ['isMaintenance'];
  const filteredData = data.filter((item) => keysToFilter.includes(item.key));

  return (
    <>
      {filteredData.length > 0 &&
        filteredData.map((item, index) => (
          <div className="col-12 flex" key={index}>
            <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
            <div className="col-6">
              <Switch checkedChildren="Bật" unCheckedChildren="Tắt" defaultChecked={item?.value === 'true'} style={{ width: 60, marginTop: '5px' }} onChange={(e) => handleChangeSwitch(e)} />
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => {
                    handleGetKey(item?.key);
                  }}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
            <div style={{ margin: '10px 0px' }} />
          </div>
        ))}
    </>
  );
};

export default RenderSwitch;
