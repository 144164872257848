import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { Button, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";
import API, { ADMIN_MINES } from "../../../network/api";
import { RIGHTS } from "../../../constant/rights";
import {hasRight} from "../../../components/until/right";
import CustomInputNumber from "../../../components/common/CustomInputNumber";
import RenderSwitch from "../../../components/common/Switch";

const MinesSetting = () => {
  const [isMaintenance, setIsMaintenance] = useState()
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const labels = {
    num_of_mine_df: 'Số lượng mìn mặc định',
    firstLose: 'Thắng lần đầu (đơn vị 1 = 1000 VND)',
    ratesWin: 'Tỉ lệ thắng',
    ratioRev: 'Tỉ lệ rev',
    ratioBonus: 'Tỉ lệ bonus',
    fees: 'Fees',
    bias: 'Bias',
    ratio_per_difficult: 'Tỉ lệ theo độ khó',
  };
  const keysToFilter = ['isMaintenance', 'firstLose', 'ratesWin', 'ratioRev', 'ratioBonus', 'fees', 'bias', 'ratio_per_difficult'];

  useEffect(() => {
    const getDataMines = async () => {
      const response = await API.get(`${ADMIN_MINES}/getConfig`);
      const resultGame = response.data?.data?.result;
      setData(resultGame);
    };

    getDataMines();
  }, []);

  const showConfirm = (key) => {
    let params;
    const id = data?.find(x => x.key === key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString()
        }
        break;
      case "firstLose":
        params = {
          key: "firstLose",
          value:valueToUpdate[key].toString()
        }
        break;
       case "ratesWin":
        params = {
          key: "ratesWin",
          value:valueToUpdate[key].toString()
        }
        break;
      case "ratioRev":
        params = {
          key: "ratioRev",
          value:valueToUpdate[key].toString()
        }
        break;
      case "ratioBonus":
        params = {
          key: "ratioBonus",
          value:valueToUpdate[key].toString()
        }
        break;
      case "fees":
        params = {
          key: "fees",
          value:valueToUpdate[key].toString()
        }
        break;
      case "bias":
        params = {
          key: "bias",
          value:valueToUpdate[key].toString()
        }
        break;
      case "ratio_per_difficult":
        params = {
          key: "ratio_per_difficult",
          value:valueToUpdate[key].toString()
        }
        break;
      case "1_mines":
        params = {
          key: "1_mines",
          value:valueToUpdate[key].toString()
        }
        break;
      case "2_mines":
        params = {
          key: "2_mines",
          value:valueToUpdate[key].toString()
        }
        break;
      case "3_mines":
        params = {
          key: "3_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "4_mines":
        params = {
          key: "4_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "5_mines":
        params = {
          key: "5_mines",
          value: valueToUpdate[key].toString()
        }
      break;
      case "6_mines":
        params = {
          key: "6_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "7_mines":
        params = {
          key: "7_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "8_mines":
        params = {
          key: "8_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "9_mines":
        params = {
          key: "9_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "10_mines":
        params = {
          key: "10_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "11_mines":
        params = {
          key: "11_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "12_mines":
        params = {
          key: "12_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "13_mines":
        params = {
          key: "13_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "14_mines":
        params = {
          key: "14_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "15_mines":
        params = {
          key: "15_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "16_mines":
        params = {
          key: "16_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "17_mines":
        params = {
          key: "17_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "18_mines":
        params = {
          key: "18_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "19_mines":
        params = {
          key: "19_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "20_mines":
        params = {
          key: "20_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "21_mines":
        params = {
          key: "21_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "22_mines":
        params = {
          key: "22_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "23_mines":
        params = {
          key: "23_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "24_mines":
        params = {
          key: "24_mines",
          value: valueToUpdate[key].toString()
        }
        break;
      case "num_of_mine_df":
        params = {
          key: "num_of_mine_df",
          value: valueToUpdate[key].toString()
        }
        break;
      default: break;
    }
    const dataReq = {
      id: id,
      params: params
    }
    setDataUp(dataReq);
    setIsShowModalConfirm(true)
  }

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(`${ADMIN_MINES}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then(response => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      }).catch((error) => {
        console.log(error);
      })
  }
	
  const filteredData = data.filter((item) => item.key !== 'isMaintenance');

  const sortedRatio = filteredData.sort((a, b) => {
    const withRatio = keysToFilter.includes(a.key);
    const withoutRatio = keysToFilter.includes(b.key);

    if (withRatio && !withoutRatio) return -1;
    if (!withRatio && withoutRatio) return 1;

    return 0;
  });

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const handleInputChange = (key, newValue) => {
    setValueToUpdate((prev) => ({ ...prev, [key]: Number(newValue) }));
  };
	
  const renderInputFields = () => {
    if (!data || data.length === 0) return;

    return (
      <>
        {sortedRatio.length > 0 &&
          sortedRatio?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key] || item.key}</div>
                <div className="col-6">
                  <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleInputChange(item.key, e)} />
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };

  return (
    <>
    { data ? 
      <div className="col-12 flex-col"
        style={{ display: "flex", marginTop: 30, alignItems: "center" }}
      >
        <RenderSwitch data={data} labels={labels} handleChangeSwitch={handleChangeSwitch} hasRight={hasRight} handleGetKey={handleGetKey} />
        <div style={{ margin: "10px 0px" }} />
        {renderInputFields()}
        <div style={{ margin: "10px 0px" }} />
      </div> : null }
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => { setIsShowModalConfirm(false); setDataUp(undefined) }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  )
}

export default MinesSetting;