import React, { useState, useEffect } from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { Form, Spin, Table, Pagination, Tooltip, Modal, Input, Button, DatePicker, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { TEXT_DEF } from '../../constant/text';
import { RIGHTS } from '../../constant/rights';
import { hasRight } from '../../components/until/right';
import { CopyOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment-timezone';
import API, { ADMIN_TRADITION, ORDERS_CONFIRM, ORDERS_WARNING, BOOKMAKER, GAME } from '../../network/api';
import 'moment-timezone';
import * as _ from 'lodash';
import { dataProvinces } from '../../constant/province';

const { Option } = Select;

const CheckCheating = () => {
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [bookmarkerSelected, setBookmarkerSelected] = useState();
  const [bookmarkers, setBookmarkers] = useState();
  const [ordersInfo, setOrdersInfo] = useState([]);
  const [date, setDate] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [open, setOpen] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [gameCategory, setGameCategory] = useState();
  const [game, setGame] = useState();
  const [gameTypes, setGameTypes] = useState();
  const [chooseProvince, setChooseProvince] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [disabledBtnApprove, setDisabledBtnApprove] = useState({});
  const [params, setParams] = useState("");
  const province = dataProvinces[0].all;
  const auth = JSON.parse(localStorage.getItem('auth')) || {};
  const transformStatus = {
    closed: 'Đã đóng',
    pending: 'Đang mở',
    canceled: 'Đã huỷ',
    warning: 'Cảnh báo',
  };

  const [finalInfo, setFinalInfo] = useState({
    count: 0,
    paymentWin: 0,
    totalBet: 0,
  });

  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  useEffect(() => {
    getBookmarkers();
  }, []);

  useEffect(() => {
    form.setFieldValue('gameType', undefined);
    setGame(undefined);
    if (gameCategory === 'lottery') {
      getGameTypes(0);
    } else if (gameCategory === 'casino') {
      getGameTypes(1);
    } else {
      getGameTypes(-1);
    }
  }, [gameCategory]);

  useEffect(() => {
    getDoubtOrdersCheating2();
  }, [page, pageSize, params]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const updatedStatus = {};
  //     ordersInfo.forEach((item) => {
  //       updatedStatus[item.id] = canActiveBtnApprove(item.type);
  //     });
  //     setDisabledBtnApprove(updatedStatus);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [ordersInfo]);

  const getDoubtOrdersCheating2 = async () => {
    if (!params) return;
    setLoading(true);

    try {
      const searchParams = params ? params : '';
      const response = await API.get(`${ADMIN_TRADITION}/${ORDERS_WARNING}?take=${pageSize}&skip=${page}&${searchParams}`);
      const result = response?.data?.data?.orders || [];
      const sumOrders = response?.data?.data?.sumOrders || {};
      const total = response?.data?.data?.total || 0;
      const listOrdersWarning = [];

      for (let item of result) {
        const status = transformStatus[item?.status];

        listOrdersWarning.push({
          id: item?.id,
          username: item?.user?.username,
          codeOder: item?.numericalOrder,
          revenue: dollarUS.format(Number(item.revenue)),
          province: formatTextGameType(item?.province),
          createdAt: item?.createdAt,
          turnIndex: item?.turnIndex.replace(/\//g, '-'),
          paymentWin: dollarUS.format(Number(item.predictPaymentWin)),
          bookmakerName: item?.bookMaker?.name,
          bookmakerId: item?.bookMaker?.id,
          type: item?.type,
          state: item?.province,
          status: status,
          adminApprove: item?.approver,
        });
      }
      setLoading(false);
      setOrdersInfo(listOrdersWarning);
      setTotalRecord(total);
      setFinalInfo({
        count: sumOrders?.count,
        paymentWin: sumOrders?.paymentWin,
        totalBet: sumOrders?.totalBet,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const formatTextGameType = (province) => {
    const result = dataProvinces[0].all[province];

    return `Xổ Số ${result}`;
  };

  const getBookmarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    setBookmarkers(tempBookmarkers);
    // setBookmarkerSelected(tempBookmarkers?.[0]?.key);
  };

  const getGameTypes = async (type = null) => {
    const gameTypesXSTT = await getGameTypesXSTT();
    let allGames = gameTypesXSTT;
    if (type !== 1) {
      allGames = [...gameTypesXSTT];
    }

    setGameTypes(allGames);
  };

  const getGameTypesXSTT = async () => {
    const result = await API.get(`${GAME}/xstt`);
    if (!result?.data?.data || result?.data?.data?.length === 0) {
      return [];
    }

    const games = result?.data?.data || [];
    const { gamesMT, gamesMB, gamesMN } = games.reduce(
      (init, currentValue) => {
        if (currentValue.parrentType === 'xsmt') {
          init.gamesMT.push(currentValue);
        } else if (currentValue.parrentType === 'xsmn') {
          init.gamesMN.push(currentValue);
        } else {
          init.gamesMB.push(currentValue);
        }

        return init;
      },
      { gamesMT: [], gamesMB: [], gamesMN: [] }
    );
    const gamesFinal = gamesMB.concat(gamesMT).concat(gamesMN);

    return gamesFinal.map((item) => {
      return {
        key: item?.id,
        value: `${item.lotteryType}_${item.parrentType}_${item.type}`,
        label: item.textView.includes('XS') ? item.textView.replace('XS', 'Xổ Số') : item.textView,
      };
    });
  };

  const getParams = () => {
    let params = '';

    if (bookmarkerSelected) {
      params += `bookmarkerId=${bookmarkerSelected}`;
    } else {
      params += `bookmarkerId=${auth.bookmakerId}`;
    }

    if (date) {
      params += `&date=${date}`;
    }

    if (game) {
      const gameType = game.split('_')[1];
      params += `&type=${gameType}`;
    }

    if (game) {
      const province = game.split('_')[2];
      params += `&province=${province}`;
    }

    const numericalOrder = form.getFieldValue('numericalOrder');
    if (numericalOrder) {
      params += `&numericalOrder=${numericalOrder}`;
    }

    const username = form.getFieldValue('username');
    if (username) {
      params += `&username=${username.trim()}`;
    }

    setParams(params);
  };

  const onChangePage = (page, size) => {
    setPage(page);
    setPageSize(size);
  };

  const showTotal = (total) => `Tổng cộng ${total} đơn cược`;

  const onSearch = () => {
    getParams();
    setPage(1);
  };

  const column = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(page - 1) * pageSize + (index + 1)}</span>,
      fixed: 'left',
      width: 80,
    },
    {
      title: 'Tên HV mã hóa',
      key: 'username',
      render: (record) => (
        <div>
          <div className="flex w-full items-center justify-center">
            <div>{record.username}</div>
            {record.username ? <CopyOutlined className="copy-icon" onClick={() => copyToClipboard(record?.username)} /> : ''}
          </div>
        </div>
      ),
    },
    {
      title: 'Mã đơn cược',
      key: 'codeOder',
      render: (record) => (
        <div>
          <div className="flex w-full items-center justify-center">
            <div style={{ cursor: 'pointer', color: '#1677ff' }} onClick={() => showModal(record.id)}>
              {record.codeOder}
            </div>
            {record?.codeOder ? <CopyOutlined className="copy-icon" onClick={() => copyToClipboard(record?.codeOder)} /> : ''}
          </div>
        </div>
      ),
    },
    {
      title: TEXT_DEF.text0202,
      dataIndex: 'revenue',
      key: 'revenue',
      fixed: 'left',
    },
    {
      title: TEXT_DEF.text0203,
      dataIndex: 'paymentWin',
      key: 'paymentWin',
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: 'bookmakerName',
      key: 'bookmakerName',
      fixed: 'left',
    },
    {
      title: TEXT_DEF.text0051,
      dataIndex: 'province',
      key: 'province',
      fixed: 'left',
    },
    {
      title: 'Thời gian đặt cược',
      key: 'createdAt',
      render: (record) => moment.utc(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
      fixed: 'left',
    },
    {
      title: 'Ngày trả thưởng',
      dataIndex: 'turnIndex',
      key: 'turnIndex',
      fixed: 'left',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      fixed: 'left',
    },
    {
      title: 'Admin duyệt',
      dataIndex: 'adminApprove',
      key: 'adminApprove',
    },
    {
      title: 'Hành động',
      key: 'id',
      align: 'center',
      width: 200,
      render: (record) => (
        <>
          {hasRight([RIGHTS.RewardManagement]) ? (
            <Tooltip title="Duyệt đơn">
              <button type="button" className="btn btn-outline-warning btn-sm rounded-circle mr-1" disabled={!canActiveBtnApprove(record)} onClick={() => showModalApproveOrder(record)}>
                <EditOutlined />
              </button>
            </Tooltip>
          ) : null}
        </>
      ),
    },
  ];

  const canActiveBtnApprove = (record) => {
    const now = new Date();
    const transformNow = moment.utc(now).utcOffset(7).format('DD-MM-YYYY');

    if (record.turnIndex < transformNow && record.status === 'Cảnh báo') {
      return true;
    }

    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    if (record.status === 'Cảnh báo') {
      if (record.type === 'xsmb') {
        return currentHour > 18 || (currentHour === 18 && currentMinute >= 38);
      } else if (record.type === 'xsmt') {
        return currentHour > 17 || (currentHour === 17 && currentMinute >= 39);
      } else if (record.type === 'xsmn') {
        return currentHour > 16 || (currentHour === 16 && currentMinute >= 42);
      }
    }

    return false;
  };

  const showModal = async (id) => {
    const order = ordersInfo.find((ord) => ord.id === id);

    if (!order) {
      return;
    }
    const result = await API.get(`${ADMIN_TRADITION}/${order.id}`);
    const orderDetail = _.get(result, 'data.data', {});
    if (orderDetail.game === 'Xổ số') {
      orderDetail.awardDetail = JSON.parse(orderDetail.awardDetail);
    }

    setOrderDetail(orderDetail);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert('Sao chép thành công');
        })
        .catch((err) => {
          alert('Sao chép thất bại: ', err);
        });
    } else {
      fallbackCopyToClipboard(text);
    }
  };

  const fallbackCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      alert('Sao chép thành công');
    } catch (err) {
      alert('Sao chép thất bại: ', err);
    }
    document.body.removeChild(textArea);
  };

  const dollarUS = Intl.NumberFormat('en-US');

  const onChangeDate = (e) => {
    if (e) {
      const selectedDate = moment(e.$d);
      const formattedDate = selectedDate.format('DD/MM/YYYY');
      setDate(formattedDate);
    }
  };

  const showModalApproveOrder = async (record) => {
    setCurrentRecord(record);
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleApproveOrder = async () => {
    const body = {
      status: 'approver',
    };

    if (!currentRecord?.id || loading) {
      return;
    }

    setLoading(true);
    try {
      const response = await API.patch(`${ADMIN_TRADITION}/${ORDERS_CONFIRM}/${currentRecord?.id}`, body);
      if (response?.data?.success) {
        alert('Duyệt đơn thành công');
        getDoubtOrdersCheating2();
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setIsEditModalOpen(false);
  };

  const handleCancelModalEdit = () => {
    setIsEditModalOpen(false);
    formEdit.resetFields();
  };

  const handleChooseProvince = (value) => {
    setChooseProvince(value);
  };

  const getStatus = (orderDetail) => {
    if (!orderDetail) return;

    let status = '';
    if (orderDetail.status === 'closed') {
      status = 'Đã đóng';
    } else if (orderDetail.status === 'pending') {
      status = 'Đang mở';
    } else if (orderDetail.status === 'canceled') {
      status = 'Đã huỷ';
    } else if (orderDetail.status === 'warning') {
      status = 'Cảnh báo';
    }

    return status;
  };

  const getMoneyReturned = (orderDetail) => {
    if (!orderDetail) return <></>;

    return dollarUS.format(Number(orderDetail.paymentWin) + Number(orderDetail.revenue));
  };

  const getInfoBet = (orderDetail) => {
    if (!orderDetail) return <></>;

    return (
      <>
        {renderDetail(TEXT_DEF.text0166, transformInfo(orderDetail.detail))}
        {renderDetail(TEXT_DEF.text0167, orderDetail?.numberOfBets)}
        {renderDetail(TEXT_DEF.text0168, orderDetail?.multiple)}
        {renderDetail(TEXT_DEF.text0169, calculateAverageRevenuePerBet(orderDetail?.revenue, orderDetail?.numberOfBets, orderDetail?.multiple))}
      </>
    );
  };

  const checkWin = (detail, arrayResult, numberToCheck, betTypeName = '', childBetTypeName = '', index) => {
    if (arrayResult.length < 1) {
      return numberToCheck;
    }
    if (arrayResult[0].indexOf(',') > 0) {
      arrayResult = arrayResult[0].split(',');
    }
    // giải đề 8
    if (childBetTypeName === 'Đề Giải 8' && index !== 8) {
      return numberToCheck;
    }
    // tổng 2 số đặc biệt
    if (childBetTypeName === '2 Số Đặc Biệt') {
      if (index !== 0) {
        return numberToCheck;
      }
      if (
        ['tong-0', 'tong-1', 'tong-2', 'tong-3', 'tong-4', 'tong-5', 'tong-6', 'tong-7', 'tong-8', 'tong-9', 'tong-10', 'tong-11', 'tong-12', 'tong-13', 'tong-14', 'tong-15', 'tong-16', 'tong-17', 'tong-18'].includes(
          detail
        )
      ) {
        if (index === 0) {
          const numberStr = numberToCheck.slice(-2);
          const sum = parseInt(numberStr.charAt(0)) + parseInt(numberStr.charAt(1));
          const parts = detail.split('-');
          return sum === parseInt(parts[1]) ? numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>` : numberToCheck;
        }
      }
      if (['tong-tai'].includes(detail)) {
        if (index === 0) {
          const numberStr = numberToCheck.slice(-2);
          const sum = parseInt(numberStr.charAt(0)) + parseInt(numberStr.charAt(1));
          return sum >= 10 ? numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>` : numberToCheck;
        }
      }
      if (['tong-xiu'].includes(detail)) {
        if (index === 0) {
          const numberStr = numberToCheck.slice(-2);
          const sum = parseInt(numberStr.charAt(0)) + parseInt(numberStr.charAt(1));
          return sum <= 8 ? numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>` : numberToCheck;
        }
      }
      if (['tai'].includes(detail)) {
        const numberStr = numberToCheck.slice(-2);
        if (parseInt(numberStr) >= 50) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>`;
        }
      }
      if (['xiu'].includes(detail)) {
        const numberStr = numberToCheck.slice(-2);
        if (parseInt(numberStr) <= 49) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>`;
        }
      }
      if (['chan'].includes(detail)) {
        if (numberToCheck % 2 === 0) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>`;
        }
      }
      if (['le'].includes(detail)) {
        if (numberToCheck % 2 === 0) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>`;
        }
      }

      if (['tong-le'].includes(detail)) {
        const lastTwoDigits = numberToCheck.toString().slice(-2);

        const digit1 = parseInt(lastTwoDigits[0]);
        const digit2 = parseInt(lastTwoDigits[1]);

        let sum = digit1 + digit2;

        if (sum % 2 !== 0) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>`;
        }
      }

      if (['tong-chan'].includes(detail)) {
        const lastTwoDigits = numberToCheck.toString().slice(-2);

        const digit1 = parseInt(lastTwoDigits[0]);
        const digit2 = parseInt(lastTwoDigits[1]);

        let sum = digit1 + digit2;

        if (sum % 2 === 0) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${numberToCheck.slice(-2)}</span>`;
        }
      }
    }

    if (childBetTypeName === '3 Càng Giải 7' && index !== 7) {
      return numberToCheck;
    }

    if (childBetTypeName === '4 Càng Đặc Biệt' && index !== 0) {
      return numberToCheck;
    }

    // giải đề đặc biệt
    if (childBetTypeName === 'Đề Đặc Biệt' && index !== 0) {
      return numberToCheck;
    }
    // console.log(detail, numberToCheck)
    if (childBetTypeName === 'Đề Đầu Đặc Biệt') {
      if (index !== 0) {
        return numberToCheck;
      }

      const numberStr = numberToCheck.toString();
      const numCheck = numberStr.slice(0, 2);
      if (arrayResult.includes(numCheck)) {
        return `<span style="color: red">${numberStr.slice(0, 2)}</span>` + numberStr.slice(2, 5);
      }
    }
    if (childBetTypeName === 'Lô 2 Số Đầu') {
      const numberStr = numberToCheck.toString();
      const numCheck = numberStr.slice(0, 2);
      if (arrayResult.includes(numCheck)) {
        return `<span style="color: red">${numberStr.slice(0, 2)}</span>` + numberStr.slice(2, 5);
      }

      return numberToCheck;
    }

    if (childBetTypeName === 'Đề Đầu Giải Nhất') {
      if (index !== 1) {
        return numberToCheck;
      }
      const numberStr = numberToCheck.toString();
      const numCheck = numberStr.slice(0, 2);
      if (arrayResult.includes(numCheck)) {
        return `<span style="color: red">${numberStr.slice(0, 2)}</span>` + numberStr.slice(2, 5);
      }
    }

    if (childBetTypeName === 'Đuôi' && index !== 0) {
      return numberToCheck;
    }
    if (childBetTypeName === 'Đầu') {
      // const number = 437873;
      if (index === 0) {
        const numberStr = numberToCheck.toString();
        const secondLastDigit = numberStr.charAt(numberStr.length - 2);

        if (arrayResult.includes(secondLastDigit)) {
          const beforeSecondLastDigit = numberStr.slice(0, -2);
          const afterSecondLastDigit = numberStr.slice(-1);

          return beforeSecondLastDigit + `<span style="color: red">${secondLastDigit}</span>` + afterSecondLastDigit;
        }
      }
      return numberToCheck;
    }
    if (childBetTypeName === 'Đề Đầu Đuôi') {
      if (index !== 8 && index !== 0) {
        return numberToCheck;
      }
    }

    const number = arrayResult[0].length;
    const lastTwoDigits = numberToCheck.slice(-number);
    let output = numberToCheck;
    if (arrayResult.includes(lastTwoDigits)) {
      output = numberToCheck.slice(0, -number) + `<span style="color: red">${lastTwoDigits}</span>`;
      return output;
    }
    return numberToCheck;
  };

  const showHtml = (awardDetails) => {
    return `${awardDetails.join(' - ')}`;
  };

  const getResult = (orderDetail) => {
    if (['pending'].includes(orderDetail.status)) {
      return <></>;
    }
    if (!orderDetail) return <></>;

    let betNumbers = [];

    betNumbers = orderDetail?.winningNumbers?.length > 0 ? JSON.parse(orderDetail.winningNumber[0]?.winningNumbers) : [];
    if (betNumbers.length > 0 && betNumbers[0].includes(',')) {
      betNumbers = betNumbers[0].split(',');
    }

    let orderDetailT = orderDetail;

    if (typeof orderDetail.awardDetail == 'string') {
      orderDetailT.awardDetail = JSON.parse(orderDetail.awardDetail);
    }

    let content = '';
    if (orderDetailT?.type === 'xsmb') {
      const giaiDacBietHtml = showHtml([
        orderDetailT?.awardDetail?.[0]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[0]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 0) : '',
      ]);
      const giaiNhatHtml = showHtml([
        orderDetailT?.awardDetail?.[1]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[1]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 1) : '',
      ]);
      const giaiNhiHtml = showHtml([
        orderDetailT?.awardDetail?.[2]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[2]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 2) : '',
        orderDetailT?.awardDetail?.[2]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[2]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 2) : '',
      ]);
      const giai3Hhtml = showHtml([
        orderDetailT?.awardDetail?.[3]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
        orderDetailT?.awardDetail?.[3]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
        orderDetailT?.awardDetail?.[3]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
        orderDetailT?.awardDetail?.[3]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
        orderDetailT?.awardDetail?.[3]?.[4] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[4], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
        orderDetailT?.awardDetail?.[3]?.[5] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[5], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
      ]);
      const giai4Hhtml = showHtml([
        orderDetailT?.awardDetail?.[4]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : '',
        orderDetailT?.awardDetail?.[4]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : '',
        orderDetailT?.awardDetail?.[4]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : '',
        orderDetailT?.awardDetail?.[4]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : '',
      ]);
      const giai5Hhtml = showHtml([
        orderDetailT?.awardDetail?.[5]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
        orderDetailT?.awardDetail?.[5]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
        orderDetailT?.awardDetail?.[5]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
        orderDetailT?.awardDetail?.[5]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
        orderDetailT?.awardDetail?.[5]?.[4] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[4], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
        orderDetailT?.awardDetail?.[5]?.[5] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[5], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
      ]);
      const giai6Hhtml = showHtml([
        orderDetailT?.awardDetail?.[6]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : '',
        orderDetailT?.awardDetail?.[6]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : '',
        orderDetailT?.awardDetail?.[6]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : '',
      ]);
      const giai7Hhtml = showHtml([
        orderDetailT?.awardDetail?.[7]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : '',
        orderDetailT?.awardDetail?.[7]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : '',
        orderDetailT?.awardDetail?.[7]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : '',
        orderDetailT?.awardDetail?.[7]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : '',
      ]);

      content = `
          Giải đặc biệt: ${giaiDacBietHtml}
          Giải nhất: ${giaiNhatHtml}
          Giải nhì: ${giaiNhiHtml}
          Giải ba: ${giai3Hhtml}
          Giải tư: ${giai4Hhtml}
          Giải năm: ${giai5Hhtml}
          Giải sáu: ${giai6Hhtml}
          Giải bảy: ${giai7Hhtml}
        `;

      return (
        <div>
          <div className="flex-space-between">
            {/* <div><label style={{fontWeight: 'bold'}} className="label" >Kỳ:</label> {orderDetail.turnIndex}</div> */}
            {/* <div><label className="label">
              {
                (orderDetail.status === "closed" ? 'Đã xổ' : 'Chưa xổ')
              }
            </label></div> */}
          </div>
          <div className="result" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      );
    } else {
      content = `
          Giải đặc biệt: ${orderDetailT?.awardDetail?.[0]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[0]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 0) : ''}
          Giải nhất: ${orderDetailT?.awardDetail?.[1]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[1]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 1) : ''}
          Giải nhì: ${orderDetailT?.awardDetail?.[2]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[2]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 2) : ''}
          Giải ba: ${orderDetailT?.awardDetail?.[3]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : ''} - ${
        orderDetailT?.awardDetail?.[3]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : ''
      }
          Giải tư: ${orderDetailT?.awardDetail?.[4]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''} - ${
        orderDetailT?.awardDetail?.[4]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''
      } - ${orderDetailT?.awardDetail?.[4]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''} - ${
        orderDetailT?.awardDetail?.[4]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''
      }
          ${orderDetailT?.awardDetail?.[4]?.[4] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[4], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''} - ${
        orderDetailT?.awardDetail?.[4]?.[5] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[5], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''
      } - ${orderDetailT?.awardDetail?.[4]?.[6] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[6], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''}
          Giải năm: ${orderDetailT?.awardDetail?.[5]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : ''}
          Giải sáu: ${orderDetailT?.awardDetail?.[6]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : ''} - ${
        orderDetailT?.awardDetail?.[6]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : ''
      } - ${orderDetailT?.awardDetail?.[6]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : ''}
          Giải bảy: ${orderDetailT?.awardDetail?.[7]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : ''}
          Giải tám: ${orderDetailT?.awardDetail?.[8]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[8]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 8) : ''}
        `;

      return (
        <div>
          <div className="flex-space-between">
            {/* <div><label style={{fontWeight: 'bold'}} className="label" >Kỳ:</label> {orderDetail.turnIndex}</div> */}
            {/* <div><label className="label">
              {
                (orderDetail.status === "closed" ? 'Đã xổ' : 'Chưa xổ')
              }
            </label></div> */}
          </div>
          <div className="result" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      );
    }

    return <></>;
  };

  const renderDetail = (label, value) => (
    <div className="custom-break">
      <label className="label">{label}: </label> {value}
    </div>
  );

  const transformInfo = (detail) => {
    switch (detail) {
      case 'chan':
        return 'Chẵn';
      case 'le':
        return 'Lẻ';
      case 'tai':
        return 'Tài';
      case 'xiu':
        return 'Xỉu';
      case 'tong-tai':
        return 'Tổng tài';
      case 'tong-xiu':
        return 'Tổng xỉu';
      case 'tong-1':
        return 'Tổng 1';
      case 'tong-1':
        return 'Tổng 1';
      case 'tong-2':
        return 'Tổng 2';
      case 'tong-3':
        return 'Tổng 3';
      case 'tong-4':
        return 'Tổng 4';
      case 'tong-5':
        return 'Tổng 5';
      case 'tong-6':
        return 'Tổng 6';
      case 'tong-7':
        return 'Tổng 7';
      case 'tong-8':
        return 'Tổng 8';
      case 'tong-9':
        return 'Tổng 9';
      case 'tong-10':
        return 'Tổng 10';
      case 'tong-11':
        return 'Tổng 11';
      case 'tong-12':
        return 'Tổng 12';
      case 'tong-13':
        return 'Tổng 13';
      case 'tong-14':
        return 'Tổng 14';
      case 'tong-15':
        return 'Tổng 15';
      case 'tong-16':
        return 'Tổng 16';
      case 'tong-17':
        return 'Tổng 17';
      case 'tong-18':
        return 'Tổng 18';
      case 'tong-le':
        return 'Tổng lẻ';
      case 'tong-chan':
        return 'Tổng chẵn';
      default:
        return detail;
    }
  };

  const calculateAverageRevenuePerBet = (revenue, numberOfBets, multiple) => {
    const parseRevenue = parseFloat(revenue);
    const result = parseRevenue / numberOfBets / multiple;
    const formattedNumber = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedNumber;
  };

  const renderTable = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Form form={form} layout="inline" autoComplete="off" onFinish={() => onSearch()}>
              <>
                <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
                  {hasRight([RIGHTS.Super]) ? (
                    <div className="col-4">
                      <Form.Item name="bookmaker" label={TEXT_DEF.text0011}>
                        <Select
                          showSearch
                          placeholder="Chọn nhà cái"
                          optionFilterProp="children"
                          onChange={(val) => setBookmarkerSelected(val)}
                          filterOption={filterOptionBm}
                          options={bookmarkers}
                          allowClear
                          onDropdownVisibleChange={null}
                          loading={loading}
                        />
                      </Form.Item>
                    </div>
                  ) : null}
                  <div className="col-4">
                    <Form.Item name="numericalOrder" label={`Mã đơn cược`}>
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                  </div>
                  <div className="col-4">
                    <Form.Item name="username" label={`Tên HV mã hóa`}>
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
                  <div className="col-4">
                    <Form.Item name="gameType" className="col-11" label={`Game`}>
                      <Select showSearch optionFilterProp="children" onChange={(val) => setGame(val)} filterOption={filterOptionBm} options={gameTypes} allowClear></Select>
                    </Form.Item>
                  </div>
                  {/* <div className="col-4">
                    <Form.Item name="province" className="col-11" label={`Tỉnh/Thành phố`}>
                      <Select placeholder="Chọn tỉnh/thành phố" style={{ width: 300 }} onChange={handleChooseProvince}>
                        {Object.entries(province).map(([key, value]) => (
                          <Option key={key} value={key}>
                            {value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div> */}
                  <div className="col-4">
                    <Form.Item
                      name="date"
                      label={TEXT_DEF.text0204}
                      rules={[
                        {
                          required: true,
                          message: TEXT_DEF.text0004,
                        },
                      ]}
                    >
                      <DatePicker placeholder="" onChange={(e) => onChangeDate(e)} format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                    </Form.Item>
                  </div>
                </div>
              </>
              <div className="action">
                <Button htmlType="submit" type="primary">
                  {TEXT_DEF.text0026}
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <Spin spinning={loading} delay={500} size="large" style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              marginTop: 5,
              marginRight: 20,
            }}
          >
            <div className="text-lg font-bold mr-5">
              <label>Tổng số lượng đơn cược: </label> <span>{dollarUS.format(finalInfo.count)}</span>
            </div>
            <div className="text-lg font-bold mr-5">
              <label>Tổng số tiền cược: </label> <span>{dollarUS.format(finalInfo.totalBet)}</span>
            </div>
            <div className="text-lg font-bold mr-5">
              <label>Tổng số tiền trúng dự kiến: </label> <span>{dollarUS.format(finalInfo.paymentWin)}</span>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
            <Table key="whitelist" locale={locale} columns={column} dataSource={ordersInfo} pagination={false} bordered style={{ width: '100%' }} />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 15,
              justifyContent: 'flex-end',
            }}
          >
            <Pagination current={page} total={totalRecord} onChange={onChangePage} showSizeChanger showTotal={showTotal} pageSizeOptions={['10', '50', '100', '200', '500', '1000']} />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      {renderTable()}

      <Modal
        title="Duyệt đơn cược"
        open={isEditModalOpen}
        okText="Lưu"
        onCancel={handleCancelModalEdit}
        cancelText="Huỷ"
        okButtonProps={{
          autoFocus: true,
          htmlType: 'submit',
          disabled: isSubmitting,
        }}
        destroyOnClose
        modalRender={(dom) => (
          <Form layout="vertical" form={formEdit} name="form_in_modal" initialValues={{ modifier: 'public' }} clearOnDestroy onFinish={() => handleApproveOrder()} autoComplete="off">
            {dom}
          </Form>
        )}
      >
        <div>
          <p>Bạn có muốn duyệt đơn cược này không?</p>
        </div>
        <div className="form-group col-12 flex justify-center flex-row">
          <div className="col-3">
            <Form.Item>
              <div className="lg-btn">
                <Button type="primary" htmlType="submit">
                  Duyệt
                </Button>
              </div>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item>
              <div className="lg-btn">
                <Button type="default" onClick={handleCancelModalEdit}>
                  Huỷ
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </Modal>

      <Modal
        title="Chi tiết đơn cược"
        open={open}
        onCancel={() => setOpen(false)}
        centered={true}
        footer={
          <Button key="submit" type="primary" onClick={hideModal}>
            Đóng
          </Button>
        }
      >
        <div className="order-detail">
          <div className="content">
            <div>
              <label className="label">Mã đơn cược: </label> {orderDetail.numericalOrder}
            </div>
            <div>
              <label className="label">Trạng thái: </label> {getStatus(orderDetail)}
            </div>
            <div>
              <label className="label">Thời gian đặt cược:&nbsp;</label>
              {orderDetail.game === 'casino' ? (
                <label>
                  {' '}
                  {new Date(orderDetail.openTime).toLocaleDateString()} - {new Date(orderDetail.openTime).toLocaleTimeString()}
                </label>
              ) : (
                // <label> {(new Date((orderDetail.openTime))).toLocaleDateString()} - {(new Date((orderDetail.openTime))).toLocaleTimeString()}</label>
                <label>
                  {new Date(orderDetail.createdAt)
                    .toLocaleString('vi-VN', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: false,
                    })
                    .replace(',', '')}
                </label>
              )}
            </div>
            <div>
              <label className="label">Thời gian kết thúc:&nbsp;</label>
              {orderDetail.game === 'casino' ? (
                <label>
                  {' '}
                  {orderDetail.status == 'pending' ? '--------' : new Date(orderDetail.closeTime).toLocaleDateString()} -{' '}
                  {orderDetail.status == 'pending' ? '--------' : new Date(orderDetail.closeTime).toLocaleTimeString()}
                </label>
              ) : (
                // <label> {(new Date((Number(orderDetail.closeTime)))).toLocaleDateString()} - {(new Date((Number(orderDetail.closeTime)))).toLocaleTimeString()}</label>
                // <label> {moment((new Date((Number(orderDetail.closeTime))))).format('MM/DD/yyy - hh:mm:ss A')}</label>
                <label>
                  {orderDetail.status == 'pending'
                    ? '--------'
                    : new Date(orderDetail.updatedAt)
                        .toLocaleString('vi-VN', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })
                        .replace(',', '')}
                </label>
              )}
            </div>
            <div className="flex-space-between">-----------------</div>
            <div>
              <label className="label">Tiền đặt cược: {dollarUS.format(Number(orderDetail.revenue))}</label>
            </div>
            <div>
              <label className="label">Tiền nhận lại: {orderDetail.status === 'pending' || orderDetail.status === 'warning' ? '--------' : getMoneyReturned(orderDetail)}</label>
            </div>
            <div className="flex-space-between">-----------------</div>
            <div className="flex-space-between">
              <div>
                <label className="label">Thể loại: </label> {orderDetail.game === 'lottery' ? 'Xổ số' : 'Game Việt'}
              </div>
              {/* {
                  (orderDetail.game !== "casino") ? (
                    <div><label className="label">Kỳ: </label> {orderDetail.turnIndex}</div>
                  ) : null
                } */}
            </div>
            <div>
              <label className="label">Game: </label> {formatTextGameType(orderDetail.province)}
            </div>
            {orderDetail.game !== 'casino' ? (
              <div>
                <label className="label">Kiểu chơi: </label> {orderDetail.betTypeName} - {orderDetail.childBetTypeName}
              </div>
            ) : null}

            {getInfoBet(orderDetail)}
            <div className="flex-space-between">-----------------</div>
            <>
              <>
                <div>
                  <span style={{ fontWeight: 500, fontSize: '13px' }}>Kỳ:</span> <span>{orderDetail.turnIndex}</span>
                </div>
                {orderDetail.game !== 'casino' && ['pending'].includes(orderDetail.status) ? (
                  <div>
                    <span style={{ fontWeight: 500, fontSize: '13px' }}>Kết quả:</span> <span>{['pending'].includes(orderDetail.status) ? 'Chưa xổ' : ''}</span>
                  </div>
                ) : (
                  <></>
                )}
                {orderDetail.game !== 'casino' && ['closed'].includes(orderDetail.status) ? (
                  <div>
                    <span style={{ fontWeight: 500, fontSize: '13px' }}>Kết quả:</span> <span> Đã xổ</span>
                  </div>
                ) : (
                  <></>
                )}
                {orderDetail.status !== 'canceled' ? <>{getResult(orderDetail)}</> : null}
              </>
            </>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CheckCheating;
