import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import * as _ from "lodash";
import axios from 'axios';

export const LOGIN = 'admin-users/login';
export const BOOKMAKER = 'admin-bookmaker';
export const USER = 'admin-users';
export const SYS_LAYOUT = 'admin-sys-layout';
export const DATA_FAKE = 'admin-data-fake';
export const QA = 'admin-qa';
export const USER_QUESTION = 'admin/user/question/index';
export const ADMIN_ORDERS = 'admin-orders';
export const GAME = 'admin-game';
export const WALLET_INOUT = 'admin-wallet-inout';
export const ADMIN_DASHBOARD = 'admin-dashboard';
export const GAME_TEXT = 'admin-game-text';
export const ADMIN_POKER = 'adminPoker';
export const ADMIN_HILO = 'adminHilo';
export const ADMIN_KENO = "adminKeno";
export const ADMIN_MINES = "adminMines";
export const ADMIN_LIMBO = "adminLimbo";
export const ADMIN_TOWER = "adminTower";
export const ADMIN_DIAMOND = "adminDiamond";
export const ADMIN_COINFLIP = "adminCoinflip";
export const ADMIN_WHEEL = "adminWheel";
export const ADMIN_PLINKO = "adminPlinko";
export const MAINTENANCE = 'maintenance';

export const USER_INFO = 'userInfo';
export const ADMIN_MANAGE_USER_INFO = 'management-user-info';
export const USER_HIS = 'user-history';
export const REGISTER = 'admin-users/register';
export const COMMON = 'SysConfigs';
export const USER_CONDITION = 'UserCondition';
export const AWARD_REGUL = 'AwardRegul';
export const LOG = 'log';
export const EVENT_TIME = 'event-time';

export const Sytems_ALL = 'SysConfigs/all';
export const Sytems_ID = 'SysConfigs';
export const Ticket3 = 'Ticket3';
export const TranSaction = 'Transaction';
export const wallet = 'Wallet';
export const subWallet = 'SubWallet';
export const WHITE_LIST = 'admin/whitelist';
export const GET_TOPIC = 'get-topic';
export const GET_CLASSIC = 'admin-game/xstt';
export const CLASSIC_AWARD = 'admin-lottery-award-old';
export const ADMIN_TRADITION = 'admin-traditional-orders';
export const ORDERS_CONFIRM = 'confirm';
export const ORDERS_WARNING = 'warning';

// lottery award
export const Lottery_Award = 'admin-lottery-awards';

// ------------ router admin -------------
export const ADMIN_SETTING = 'admin-settings';
export const AMIN_BONUS_CONFIG = 'admin-bonus-setting';
export const STATISTIC = 'statistic';
export const STATISTIC_ORDERS = 'statistic/orders';
export const RANK = 'admin-ranks';
export const ROLE_CREATE = 'admin-roles';
export const ROLES = 'admin-roles';

const { confirm } = Modal;

//--------------
const baseURL = "https://api.lottery.syp400.com/api/v1";
// const baseURL = "http://localhost:3006/api/v1";

const API = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});

const showPromiseConfirm = () => {
  confirm({
    title: 'Warning!',
    icon: <ExclamationCircleFilled />,
    content: 'Bạn không có quyền truy cập!',
    async onOk() {
      const auth = JSON.parse(localStorage.getItem('auth'));
      const username = auth?.username;
      const response = await API.get(`${USER}/${username}/roles`);
      const permissions = _.get(response, 'data.data.roleAdminUser.permissions', '');
      auth.permissions = permissions;
      localStorage.setItem('auth', JSON.stringify(auth));
      window.location.assign('/');
    },
    onCancel() {},
  });
};

const auth = localStorage.getItem('auth');
if (auth) {
  API.defaults.headers.common['Authorization'] = JSON.parse(auth).tokenType + ' ' + JSON.parse(auth).accessToken;

  API.interceptors.response.use(undefined, (err) => {
    const error = err.response;
    // if error is 401
    if (error.status === 401 || error.status === 403) {
      localStorage.clear();
      window.location.assign('/login');
    }
    if (error?.data?.message?.message === 'NOT_HAVE_ACCESS') {
      showPromiseConfirm();
    }

    throw err;
  });
}

export default API;
